import React from "react";
import theme from "theme";
import { Theme, Link } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"contact"} />
		<Helmet>
			<title>
				dimple - Contact
			</title>
			<meta name={"description"} content={"dirk@dimple.be\n+32 472 61 99 33"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/646a3bab16e21c0024b31727/images/dimple-d-white-32.png?v=2023-06-07T13:08:36.406Z"} type={"image/x-icon"} />
		</Helmet>
		<Components.MenuHam>
			<Override slot="image" src="https://uploads.quarkly.io/646a3bab16e21c0024b31727/images/dimple%20logo%20-%20logo%20+%20tagline.png?v=2023-06-07T12:24:58.155Z" />
		</Components.MenuHam>
		<Components.Contact2 />
		<Components.Bottom>
			<Override slot="text" font="--base">
				<Link
					href="https://dimple.be/ALGEMENE%20VERKOOP-%20EN%20FACTUURVOORWAARDEN%20-%20DIMPLE.pdf"
					color="--primary"
					text-decoration-line="initial"
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
					font="--lead"
				>
					Algemene voorwaarden
				</Link>
				<Link
					href="https://dimple.be/DISCLAIMER PRIVACY DIMPLE.pdf"
					font="--lead"
					color="--primary"
					text-decoration-line="initial"
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
				>
					<br />
					Website disclaimer & privacy
				</Link>
				<Link
					href="https://quarkly.io/preview#/https://dimple.be/ALGEMENE%20VERKOOP-%20EN%20FACTUURVOORWAARDEN%20-%20DIMPLE.pdf"
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
				>
					<br />
					<br />
				</Link>
				(c) 2024 Dimple{"\n\n"}
			</Override>
		</Components.Bottom>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"646a3bab16e21c0024b31725"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
			<meta
				charset={""}
				name={"viewport"}
				content={"width=device-width, initial-scale=1, shrink-to-fit=no"}
				place={"endOfHead"}
				rawKey={"6471fb35a048276ccf953797"}
			/>
			<script async={false} src={""} place={"endOfHead"} rawKey={"6480503d4a1beafc87993190"}>
				{"  window.dataLayer = window.dataLayer || [];\n  function gtag(){dataLayer.push(arguments);}\n  gtag('js', new Date());\n  gtag('config', 'G-8CGV73MC0F')"}
			</script>
			<script async={true} src={"https://www.googletagmanager.com/gtag/js?id=G-8CGV73MC0F"} place={"endOfHead"} rawKey={"64805dac61988253a2113525"} />
		</RawHtml>
	</Theme>;
});